<template>
  <div class="fonts">
    <h1>
      Nanum Myeongjo:
      <span class="nanum-myeongjo">The quick brown fox jumped over the lazy dog</span>
    </h1>
    <h1>
      Lobster Two:
      <span class="lobster-two">The quick brown fox jumped over the lazy dog</span>
    </h1>
    <h1>
      Cormorant:
      <span class="cormorant">The quick brown fox jumped over the lazy dog</span>
    </h1>
  </div>
</template>

<style lang="scss">
  .fonts {
    padding: 48px 12px;

    .nanum-myeongjo {
      font-family: 'Nanum Myeongjo'
    }
    .lobster-two {
      font-family: 'Lobster Two'
    }
    .cormorant {
      font-family: 'cormorant'
    }

    h1 {
      color: #888;
      font-size: 48px;
    }
  }
</style>
