<template>
  <div class="row">
    <div class="col-sm-4">
      4
    </div>
    <div class="col-sm-6">
      6
    </div>
  </div>
</template>

<style lang="scss">

</style>
